import React, { useEffect } from "react";
import style from "../style/LeftSidebar.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveProject, setActivePreview } from "../redux/actions/project";
import Actions from "../redux/actions/news";
import { sendCampaign } from "../redux/actions/campaign";
import {
  setAddingSource,
  setPostData,
  setSelectedAudience,
  setInterviewActions,
} from "../redux/actions/main";
import { useLocation } from "react-router-dom";
import moment from "moment";

const mapDispatch = (dispatch) => ({
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setSendCampaign: (data) => dispatch(sendCampaign(data)),
  setActivePreview: (data) => dispatch(setActivePreview(data)),
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  setSelectedAudience: (feed) => dispatch(setSelectedAudience(feed)),
  setInterviewActions: (feed) => dispatch(setInterviewActions(feed)),
});
function LeftSidebar(props) {
  const routes = [
    { title: "Today", image: "calendar.png", link: "/dashboard" },
    // { title: "Brands", image: "brandIcon.svg", link: "/dashboard/brand" },
    { title: "Create", image: "projectIcon.svg", link: "/dashboard/podcast" },
    { title: "Curate", image: "brandIcon.svg", link: "/dashboard/project" },
    {
      title: "Share",
      image: "campaignIcon.svg",
      link: "/dashboard/campaign",
    },
    {
      title: "Audience",
      image: "audienceIcon.svg",
      link: "/dashboard/audience",
    },
    {
      title: "Teams",
      image: "teamsIcon.svg",
      link: "/dashboard/teams",
      disabled: true,
    },
    {
      title: "Collections",
      image: "collectingIcon.svg",
      link: "/dashboard/collection",
    },
    {
      title: "Templates",
      image: "templatesIcon.svg",
      link: "/dashboard/template",
    },
    { title: "Settings", image: "settingIcon.svg", link: "/dashboard/setting" },
  ];
  const location = useLocation();
  return (
    <div className={style.LeftSidebar}>
      {routes.map((item) => (
        <a
          href="#"
          className={`${style.sidebarItem} ${
            item.disabled ? style.disabledItem : ""
          }`}
          style={{ color: location.pathname == item.link ? "white" : "#999" }}
          onClick={(e) => {
            if (!item.disabled) {
              e.preventDefault();
              props.setActiveProject(null);
              props.setSelectedFeed(null);
              props.setSendCampaign(false);
              props.setActivePreview(null);
              props.setAddingSource("News");
              props.setSelectedAudience(null);
              props.setInterviewActions(null);
              props.setPostData({
                title: "",
                categories: [],
                editing: "none",
                voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
              });
              props.router.push(item.link);
            } else {
              return;
            }
          }}
        >
          <img
            src={
              location.pathname == item.link
                ? `/images/green${item.image}`
                : `/images/${item.image}`
            }
            alt=""
            style={{
              filter:
                location.pathname == item.link
                  ? "none"
                  : item.link === "/dashboard/podcast"
                  ? "none"
                  : "invert(1)",
            }}
          />{" "}
          {item.title}
        </a>
      ))}
    </div>
  );
}

export default connect(null, mapDispatch)(LeftSidebar);
