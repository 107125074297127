const prod = {
  api: {
    URL: "https://teamsbackend.audioone.cloud/api/v1",
  },
  serverurl: "https://teamsbackend.audioone.cloud/",
  GOOGLE_CLIENT_ID:
    "658063193596-4pmod9gsmleaditeji6a79mjoijmqigi.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerameliaprod.audioone.cloud/",
  strapi_url: "https://posts.audioone.cloud/",
  tts_url: "https://tts.audioone.cloud",
  amelia_url: "https://audioone.fyi/",
  logEnabled: false,
  version: "production",
  appType: "studio",
  embedPlayer: "https://embed.audioone.cloud",
};
const dev = {
  api: {
    URL: "https://teamsbackenddev.audioone.cloud/api/v1",
  },
  serverurl: "https://teamsbackenddev.audioone.cloud/",
  GOOGLE_CLIENT_ID:
    "658063193596-32e2dorbjgru9bg0h3prdtpgaah10ngp.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerameliadev.audioone.cloud/",
  // PLAYER_URL: "http://localhost:3001/",
  googleClientSecret: "zRZyCXFoen_PxT6tsLFIVGwk",
  strapi_url: "https://postsdev.audioone.cloud/",
  tts_url: "https://ttsdev.audioone.cloud",
  amelia_url: "https://devprofile.audioone.fyi/",
  logEnabled: false,
  version: "development",
  appType: "studio",
  embedPlayer: "https://embeddev.audioone.cloud",
};

const staging = {
  api: {
    URL: "https://teamsbackendstag.audioone.cloud/api/v1",
  },
  serverurl: "https://teamsbackendstag.audioone.cloud/",
  tts_url: "https://ttsdev.audioone.cloud",
  GOOGLE_CLIENT_ID:
    "658063193596-32e2dorbjgru9bg0h3prdtpgaah10ngp.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "380088062878594",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerstag.audioone.cloud/",
  strapi_url: "https://postsdev.audioone.cloud/",
  amelia_url: "https://stagingprofile.audioone.fyi/",
  logEnabled: false,
  version: "staging",
  appType: "studio",
  embedPlayer: "https://embeddev.audioone.cloud",
};

const local = {
  api: {
    URL: "https://teams-um.audioone.cloud/api/v1",
  },
  serverurl: "https://teams-um.audioone.cloud/",
  tts_url: "https://ttsdev.audioone.cloud",

  GOOGLE_CLIENT_ID:
    "658063193596-32e2dorbjgru9bg0h3prdtpgaah10ngp.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "2455129998053566",
  TOMTOM: "dK07vJzhtvmkNJt44D4RwSVJt7GmFaW7",
  WHEATHERSTACK: "953f81c99ab907e28ce5d3dd820011bd",
  GEOCODE: "AIzaSyBpB8NUTEKPyDfOzPrG8eq81Tzh8QifrkU",
  PLAYER_URL: "https://playerameliadev.audioone.cloud/",
  strapi_url: "https://postsdev.audioone.cloud/",
  amelia_url: "https://devprofile.audioone.fyi/",
  logEnabled: false,
  version: "local",
  appType: "studio",
  embedPlayer: "https://embeddev.audioone.cloud",

  // PLAYER_URL: "http://localhost:3001/",
};

// console.log(process.env.REACT_APP_STAGE);

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE == "development"
    ? dev
    : process.env.REACT_APP_STAGE == "staging"
    ? staging
    : local;

export default {
  ...dev,
};
